/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import {
  Switch,
  BrowserRouter as Router,
  Redirect,
} from 'react-router-dom';
import Loadable from 'react-loadable';
import Route from './Route';
import LoadingOverlay from './LoadingOverlay';

const Async = (loader) => Loadable(
  {
    loader,
    loading: LoadingOverlay,
  },
);

const Login = Async(() => import('../components/auth/Login'));
const VerificationConfirmation = Async(() => import('../components/auth/VerificationConfirmation'));
const ForgotPassword = Async(() => import('../components/auth/ForgotPassword'));
const ResetPassword = Async(() => import('../components/auth/ResetPassword'));
const VenueCrud = Async(() => import('../components/venueAdmin/VenueCrud'));
const VenueListing = Async(() => import('../components/venueAdmin/VenueListing'));
const TalentCrud = Async(() => import('../components/talentAdmin/TalentCrud'));
const TalentListing = Async(() => import('../components/talentAdmin/TalentListing'));
const MemberEventCrud = Async(() => import('../components/memberEventAdmin/MemberEventCrud'));
const MemberEventManager = Async(() => import('../components/memberEventAdmin/MemberEventManager'));
const MemberEventListing = Async(() => import('../components/memberEventAdmin/MemberEventListing'));
const UserListing = Async(() => import('../components/userAdmin/UserListing'));

const MemberEvent = Async(() => import('../components/memberEvent/MemberEvent'));
const LegacyMemberEventClaim = Async(() => import('../components/memberEvent/LegacyMemberEventClaim'));
const LegacyMemberEventClaimConfirmation = Async(() => import('../components/memberEvent/LegacyMemberEventClaimConfirmation'));
const MemberEventClaim = Async(() => import('../components/memberEvent/MemberEventClaim'));
const MemberEventPurchase = Async(() => import('../components/memberEvent/MemberEventPurchase'));
const MemberEventPayment = Async(() => import('../components/memberEvent/MemberEventPayment'));
const MemberEventConfirmation = Async(() => import('../components/memberEvent/MemberEventConfirmation'));

const Profile = Async(() => import('../components/profile/Profile'));
const ChangePassword = Async(() => import('../components/profile/ChangePassword'));
const MyEvents = Async(() => import('../components/myEvents/MyEvents'));
const Wallet = Async(() => import('../components/wallet/Wallet'));

const LandingPage = Async(() => import('../components/landingPage/LandingPage'));
const ShortLeadCapture = Async(() => import('../components/leadCapture/shortLeadCapture/ShortLeadCapture'));

const CustomMarketingPage = Async(() => import('../components/customMarketingPage/CustomMarketingPage'));
const LongLeadCapture = Async(() => import('../components/leadCapture/longLeadCapture/LongLeadCapture'));
const LongLeadCaptureConfirmation = Async(() => import('../components/leadCapture/longLeadCapture/LongLeadCaptureConfirmation'));

const PrivacyPage = Async(() => import('../components/privacy/Privacy'));

const SuitesMarketingPage = Async(() => import('../components/suitesMarketingPage/SuitesMarketingPage'));
const VividSeatsAdmin = Async(() => import('../components/vividSeatsAdmin/VividSeatsAdmin'));
/*
  -- Route Flags --
  guestOnly - Redirect logged in users home
  adminOnly - Redirect non-admins home
  loginRequired - Redirect guests to login
  diableNav - No nav bar
  disableRecording - No LogRocket session recording
*/

const { REACT_APP_ENABLE_LEAD_CAPTURE_RECORDING } = process.env;
const disableLeadCaptureRecording = REACT_APP_ENABLE_LEAD_CAPTURE_RECORDING === 'FALSE';

export default () => (
  <Router>
    {/* <ScrollToTop /> */}
    <Switch>
      <Route path="/login" component={Login} guestOnly disableNav />
      <Route path="/verified" component={VerificationConfirmation} loginRequired disableNav />
      <Route path="/forgot-password" component={ForgotPassword} guestOnly disableNav />
      <Route path="/password-reset" component={ResetPassword} guestOnly disableNav />
      <Route
        path="/activate"
        guestOnly
        disableNav
        render={(props) => <ResetPassword {...props} isActivation />}
      />

      <Route path="/purchase/:purchaseId" component={MemberEventConfirmation} />
      <Route path="/redemption/:creditRedemptionId" loginRequired component={MemberEventConfirmation} />

      <Route path="/member-events/:memberEventId/payment" component={MemberEventPayment} />
      <Route path="/member-events/:memberEventId/purchase" component={MemberEventPurchase} />
      <Route path="/member-events/:memberEventId" component={MemberEvent} />

      <Route
        path="/invite/:ticketClaimCode/confirmation"
        render={(props) => (
          <LegacyMemberEventClaimConfirmation {...props} isGuestPurchase={false} />
        )}
      />
      <Route
        path="/invite/:ticketClaimCode"
        render={(props) => <LegacyMemberEventClaim {...props} isGuestPurchase={false} isLegacy />}
      />

      <Route
        path="/ticket/:ticketClaimCode/confirmation"
        render={(props) => <LegacyMemberEventClaimConfirmation {...props} isGuestPurchase />}
      />
      <Route
        path="/ticket/:ticketClaimCode"
        render={(props) => <LegacyMemberEventClaim {...props} isGuestPurchase isLegacy />}
      />

      <Route
        path="/transfer/:ticketClaimCode"
        render={(props) => <MemberEventClaim {...props} />}
      />

      <Route path="/profile/change-password" component={ChangePassword} loginRequired />
      <Route path="/profile/edit" component={Profile} loginRequired />
      <Route path="/profile" component={Profile} loginRequired />

      <Route
        path="/my-events/upcoming"
        loginRequired
        render={(props) => <MyEvents {...props} type="upcoming" />}
      />
      <Route
        path="/my-events/past"
        loginRequired
        render={(props) => <MyEvents {...props} type="past" />}
      />
      <Route
        path="/my-events"
        loginRequired
        render={(props) => <MyEvents {...props} type="upcoming" />}
      />

      <Route path="/wallet" component={Wallet} loginRequired />

      <Route path="/admin/venues/new" component={VenueCrud} loginRequired adminOnly />
      <Route path="/admin/venues/:venueId" component={VenueCrud} loginRequired adminOnly />
      <Route path="/admin/venues" component={VenueListing} loginRequired adminOnly />
      <Route path="/admin/talent/new" component={TalentCrud} loginRequired adminOnly />
      <Route path="/admin/talent/:talentId" component={TalentCrud} loginRequired adminOnly />
      <Route path="/admin/talent" component={TalentListing} loginRequired adminOnly />
      <Route path="/admin/member-events/new" component={MemberEventCrud} loginRequired adminOnly />
      <Route path="/admin/member-events/:memberEventId/edit" component={MemberEventCrud} loginRequired adminOnly />
      <Route path="/admin/member-events/:memberEventId" component={MemberEventManager} loginRequired adminOnly />
      <Route path="/admin/member-events" component={MemberEventListing} loginRequired adminOnly />
      <Route path="/admin/users" component={UserListing} loginRequired adminOnly />
      <Route path="/admin/users" component={UserListing} loginRequired adminOnly />
      <Route path="/admin/vivid-seats" component={VividSeatsAdmin} loginRequired adminOnly />

      <Route path="/member-events/:memberEventId" component={MemberEvent} />

      <Route
        path="/custom/request"
        component={LongLeadCapture}
      />
      <Route
        path="/custom/confirmation"
        component={LongLeadCaptureConfirmation}
      />
      <Route
        path="/custom/:slug"
        guestOnly
        disableNav
        disableRecording={disableLeadCaptureRecording}
        render={(props) => <ShortLeadCapture {...props} requestType="custom" />}
      />
      <Route
        path="/suites/:slug"
        guestOnly
        disableNav
        disableRecording={disableLeadCaptureRecording}
        render={(props) => <ShortLeadCapture {...props} requestType="suite" />}
      />

      <Route
        path="/custom"
        component={CustomMarketingPage}
        transparentNav
      />

      <Redirect from="/premium-experiences" to="/premium-hospitality" />
      <Route
        path="/premium-hospitality"
        component={SuitesMarketingPage}
        transparentNav
      />

      <Route path="/privacy" component={PrivacyPage} />
      <Route path="/our-work" component={LandingPage} transparentNav scrollToTop={false} />
      <Route path="/" component={LandingPage} transparentNav />
    </Switch>
  </Router>
);
