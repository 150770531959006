// export const logo = 'https://storage.googleapis.com/thuzio_public/thuzio_by_triller_boxed_logo.png';
export const logo = 'https://storage.googleapis.com/thuzio_public/thuzio_logo_nobox.png';
export const footerLockup = 'https://storage.googleapis.com/thuzio_public/A%20Triller%20Company%20-%20Footer%20Lockup%20-%20Fira%20-%20White.png';
export const authSplash = 'https://storage.googleapis.com/thuzio-public/thuzio_login_splash.jpeg';
export const loading = 'https://storage.googleapis.com/thuzio_public/thuzio_loading_spinner.svg';
export const thuzioVideoPlaceholder = 'https://storage.googleapis.com/thuzio_public/thuzio_splash.png';
export const eventImagePlaceholder = 'https://storage.googleapis.com/thuzio_public/thuzio_splash.png';
export const csvUploadExample = 'https://storage.googleapis.com/thuzio-public/csv-upload-format-guide.png';
export const csvUserUploadExample = 'https://storage.googleapis.com/thuzio-public/csv-user-upload-format-guide.png';
export const quoteIcon = 'https://storage.googleapis.com/thuzio-public/thuzio_quote_icon.png';
export const sendIcon = 'https://storage.googleapis.com/thuzio-public/thuzio_send_icon.png';
export const resetPasswordIcon = 'https://storage.googleapis.com/thuzio-public/thuzio_password_reset.png';

export const homeHero = 'https://storage.googleapis.com/thuzio_public/thuzio_home_splash-min.jpg';
export const homeThuzioOriginals = 'https://storage.googleapis.com/thuzio_public/thuzio_member_event_scottie.jpg';
export const homeVirtual = 'https://storage.googleapis.com/thuzio-public/thuzio_discover_virtual.jpg';
export const homeLive = 'https://storage.googleapis.com/thuzio-public/thuzio_discover_live.jpg';
export const homePartner = 'https://storage.googleapis.com/thuzio-public/thuzio_discover_partner.jpg';

export const ticketIcon = 'https://storage.googleapis.com/thuzio-public/thuzio_ticket_icon.svg';

export const lpDesktopHero = 'https://storage.googleapis.com/thuzio_public/thuzio_hero.png';
export const lpMemberEventsHero = 'https://storage.googleapis.com/thuzio_public/thuzio_member_events_hero.png';
export const lpCustomHero = 'https://storage.googleapis.com/thuzio_public/thuzio_custom_hero.png';
export const lpTalentHero = 'https://storage.googleapis.com/thuzio_public/thuzio_talent_experiences_hero.png';

export const lpMemberEventCarouselImages = [
  'https://storage.googleapis.com/thuzio_public/thuzio_member_event_lisa_leslie.jpg',
  'https://storage.googleapis.com/thuzio_public/thuzio_member_event_evan_engram.jpg',
  'https://storage.googleapis.com/thuzio_public/thuzio_member_event_scottie.jpg',
  'https://storage.googleapis.com/thuzio_public/thuzio_member_event_tiki.jpg',
];

export const verizonLogo = 'https://storage.googleapis.com/thuzio_public/thuzio_verizon.svg';
export const verizonLogoWhite = 'https://storage.googleapis.com/thuzio_public/thuzio_verizon_white.svg';
export const aegLogo = 'https://storage.googleapis.com/thuzio_public/thuzio_aeg.svg';
export const insperityLogo = 'https://storage.googleapis.com/thuzio_public/thuzio_insperity.svg';
export const insperityLogoWhite = 'https://storage.googleapis.com/thuzio_public/thuzio_insperity_white.svg';
export const jpMorganLogo = 'https://storage.googleapis.com/thuzio_public/thuzio_jp_morgan_chase.svg';
export const jpMorganLogoWhite = 'https://storage.googleapis.com/thuzio_public/thuzio_jp_morgan_chase_white.svg';
export const bnyMellonLogo = 'https://storage.googleapis.com/thuzio_public/thuzio_bny_mellon.svg';
export const bnyMellonLogoWhite = 'https://storage.googleapis.com/thuzio_public/thuzio_bny_mellon_white.svg';
export const pepsiLogo = 'https://storage.googleapis.com/thuzio_public/thuzio_pepsi.svg';
export const pepsiLogoWhite = 'https://storage.googleapis.com/thuzio_public/thuzio_pepsi_white.svg';
export const wellsFargoLogo = 'https://storage.googleapis.com/thuzio_public/thuzio_wells_fargo.svg';
export const wellsFargoLogoWhite = 'https://storage.googleapis.com/thuzio_public/thuzio_wells_fargo_white.svg';
export const workdayLogo = 'https://storage.googleapis.com/thuzio_public/thuzio_workday.svg';
export const workdayLogoWhite = 'https://storage.googleapis.com/thuzio_public/thuzio_workday_white.svg';
export const akinGumpLogo = 'https://storage.googleapis.com/thuzio_public/thuzio_akin_gump.svg';
export const akinGumpLogoWhite = 'https://storage.googleapis.com/thuzio_public/thuzio_akin_gump_white.svg';
export const raymondJamesLogo = 'https://storage.googleapis.com/thuzio_public/thuzio_raymond_james.svg';
export const raymondJamesLogoWhite = 'https://storage.googleapis.com/thuzio_public/thuzio_raymond_james_white.svg';

export const billboardLogo = 'https://storage.googleapis.com/thuzio_public/thuzio_billboard_logo_gold.svg';
export const betakitLogo = 'https://storage.googleapis.com/thuzio_public/thuzio_betakit_logo_gold.svg';
export const bloombergLogo = 'https://storage.googleapis.com/thuzio_public/thuzio_bloomberg_logo_gold.svg';
export const forbesLogo = 'https://storage.googleapis.com/thuzio_public/thuzio_forbes_logo_gold.svg';

export const benefitsTrustIcon = 'https://storage.googleapis.com/thuzio_public/thuzio_benefits_shield.png';
export const benefitsEaseIcon = 'https://storage.googleapis.com/thuzio_public/thuzio_benefits_ease.png';
export const benefitsDiscoveryIcon = 'https://storage.googleapis.com/thuzio_public/thuzio_benefits_discovery.png';
export const benefitsValueIcon = 'https://storage.googleapis.com/thuzio_public/thuzio_benefits_value.png';

export const pricingVirtualIcon = 'https://storage.googleapis.com/thuzio_public/thuzio_virtual_pass.svg';
export const pricingProfessionalIcon = 'https://storage.googleapis.com/thuzio_public/thuzio_professional_pass.svg';
export const pricingEnterpriseIcon = 'https://storage.googleapis.com/thuzio_public/thuzio_enterprise_pass.svg';

export const leadCaptureEventTypeIcon = 'https://storage.googleapis.com/thuzio_public/video_icon_active.svg';
export const leadCaptureEventScottiePippen = 'https://storage.googleapis.com/thuzio_public/thuzio_scottie_pippen.jpg';
export const leadCaptureEventKevinOleary = 'https://storage.googleapis.com/thuzio_public/thuzio_kevin_oleary.jpg';
export const leadCaptureEventJoshCapon = 'https://storage.googleapis.com/thuzio_public/thuzio_josh_capon.jpg';
export const leadCaptureEventGaryVee = 'https://storage.googleapis.com/thuzio_public/thuzio_gary_vee.jpg';
export const leadCaptureEventBelvedere = 'https://storage.googleapis.com/thuzio_public/thuzio_belvedere.jpg';
export const leadCaptureEventAliWong = 'https://storage.googleapis.com/thuzio_public/thuzio_ali_wong.jpg';
export const leadCaptureEventLisaLeslie = 'https://storage.googleapis.com/thuzio_public/thuzio_lisa_leslie.jpg';

export const leadCaptureVenueKansasCity = 'https://storage.googleapis.com/thuzio_public/thuzio_kansas_city_chiefs.jpeg';
export const leadCaptureVenueHouston = 'https://storage.googleapis.com/thuzio_public/thuzio_houston_texans.jpeg';
export const leadCaptureVenueDetroit = 'https://storage.googleapis.com/thuzio_public/thuzio_detroit_lions.jpeg';
export const leadCaptureVenueDallas = 'https://storage.googleapis.com/thuzio_public/thuzio_dallas_cowboys.jpeg';
export const leadCaptureVenueMsg = 'https://storage.googleapis.com/thuzio_public/thuzio_msg.jpg';
export const leadCaptureVenueTdGarden = 'https://storage.googleapis.com/thuzio_public/thuzio_td_garden.jpeg';
export const leadCaptureVenueWrigleyField = 'https://storage.googleapis.com/thuzio_public/thuzio_wrigley_field.jpeg';
export const leadCaptureVenueBaltimore = 'https://storage.googleapis.com/thuzio_public/thuzio_baltimore_ravens.jpeg';
export const leadCaptureVenueAtlanta = 'https://storage.googleapis.com/thuzio_public/thuzio_atlanta_falcons.jpeg';

export const leadCaptureLiveIcon = 'https://storage.googleapis.com/thuzio_public/thuzio_lc_live_icon.jpg';
export const leadCaptureVirtualIcon = 'https://storage.googleapis.com/thuzio_public/thuzio_lc_virtual_icon.jpg';
export const leadCaptureHybridIcon = 'https://storage.googleapis.com/thuzio_public/thuzio_lc_hybrid_icon.jpg';

export const leadCaptureClientEngagementIcon = 'https://storage.googleapis.com/thuzio_public/thuzio_client_engagement_icon.svg';
export const leadCaptureProductLaunchIcon = 'https://storage.googleapis.com/thuzio_public/thuzio_product_icon.svg';
export const leadCaptureTeamBuildingIcon = 'https://storage.googleapis.com/thuzio_public/thuzio_team_building_icon.svg';
export const leadCaptureOtherIcon = 'https://storage.googleapis.com/thuzio_public/thuzio_other_icon.svg';
export const leadCaptureCelebrationIcon = 'https://storage.googleapis.com/thuzio_public/thuzio_celebration_icon.svg';
export const leadCaptureEducationIcon = 'https://storage.googleapis.com/thuzio_public/thuzio_education_icon.svg';

export const leadCaptureMixologyIcon = 'https://storage.googleapis.com/thuzio_public/thuzio_mixology_icon.svg';
export const leadCaptureKeynoteIcon = 'https://storage.googleapis.com/thuzio_public/thuzio_keynote_icon.svg';
export const leadCaptureFiresideChatIcon = 'https://storage.googleapis.com/thuzio_public/thuzio_fireside_icon.svg';
export const leadCaptureCulinaryIcon = 'https://storage.googleapis.com/thuzio_public/thuzio_culinary_icon.svg';
export const leadCaptureCocktailIcon = 'https://storage.googleapis.com/thuzio_public/thuzio_cocktail_icon.svg';

export const leadCaptureActorIcon = 'https://storage.googleapis.com/thuzio_public/thuzio_lc_actor_icon.svg';
export const leadCaptureAthleteIcon = 'https://storage.googleapis.com/thuzio_public/thuzio_lc_athlete_icon.svg';
export const leadCaptureAuthorIcon = 'https://storage.googleapis.com/thuzio_public/thuzio_lc_author_icon.svg';
export const leadCaptureBusinessIcon = 'https://storage.googleapis.com/thuzio_public/thuzio_lc_business_icon.svg';
export const leadCaptureChefIcon = 'https://storage.googleapis.com/thuzio_public/thuzio_lc_chef_icon.svg';
export const leadCaptureInfluencerIcon = 'https://storage.googleapis.com/thuzio_public/thuzio_lc_influencer_icon.svg';
export const leadCaptureNoneIcon = 'https://storage.googleapis.com/thuzio_public/thuzio_lc_none_icon.svg';

export const customLiveIcon = 'https://storage.googleapis.com/thuzio_public/thuzio_live_event_icon.svg';
export const customVirtualIcon = 'https://storage.googleapis.com/thuzio_public/thuzio_virtual_event_icon.svg';
export const customHybridIcon = 'https://storage.googleapis.com/thuzio_public/thuzio_hybrid_event_icon.svg';
export const customHowItWorksHero = 'https://storage.googleapis.com/thuzio_public/thuzio_rodman.jpg';
// export const customHowItWorksHero = 'https://storage.googleapis.com/thuzio_public/thuzio_how_it_works_hero.jpg';

export const customIdeationIcon = 'https://storage.googleapis.com/thuzio_public/thuzio_ideation_icon.svg';
export const customBrandingIcon = 'https://storage.googleapis.com/thuzio_public/thuzio_branding_icon.svg';
export const customLogisticsIcon = 'https://storage.googleapis.com/thuzio_public/thuzio_logistics_icon.svg';
export const customProductionIcon = 'https://storage.googleapis.com/thuzio_public/thuzio_event_production_icon.svg';
export const customTalentBookingIcon = 'https://storage.googleapis.com/thuzio_public/thuzio_talent_booking_icon.svg';
export const customBroadcastIcon = 'https://storage.googleapis.com/thuzio_public/thuzio_broadcst_icon.svg';
export const customAnalyticsIcon = 'https://storage.googleapis.com/thuzio_public/thuzio_analytics_icon.svg';
export const customAudienceIcon = 'https://storage.googleapis.com/thuzio_public/thuzio_audience_icon.svg';

export const customTalent1 = 'https://storage.googleapis.com/thuzio_public/thuzio_allen_iverson.jpg';
export const customTalent2 = 'https://storage.googleapis.com/thuzio_public/thuzio_owens.jpg';
export const customTalent3 = 'https://storage.googleapis.com/thuzio_public/thuzio_member_event_tiki.jpg';
export const customTalent4 = 'https://storage.googleapis.com/thuzio_public/thuzio_member_event_evan_engram.jpg';
export const customTalent5 = 'https://storage.googleapis.com/thuzio_public/thuzio_rampone.jpg';
export const customTalent6 = 'https://storage.googleapis.com/thuzio_public/thuzio_judah.jpg';

export const customFormat1 = 'https://storage.googleapis.com/thuzio-public/thuzio_discover_virtual.jpg';
export const customFormat2 = 'https://storage.googleapis.com/thuzio_public/thuzio_belvedere.jpg';
export const customFormat3 = 'https://storage.googleapis.com/thuzio_public/thuzio_custom_lt.jpg';
export const customFormat4 = 'https://storage.googleapis.com/thuzio_public/thuzio_custom_fb.jpg';

export const customBloombergLogo = 'https://storage.googleapis.com/thuzio_public/thuzio_bloomberg_custom.png';
export const customVerizonLogo = 'https://storage.googleapis.com/thuzio_public/thuzio_verizon_custom.png';
export const customBelvedereLogo = 'https://storage.googleapis.com/thuzio_public/thuzio_belvedere_custom.png';
export const custoKpmgLogo = 'https://storage.googleapis.com/thuzio_public/thuzio_kpmg_custom.png';
export const custoWorkdayLogo = 'https://storage.googleapis.com/thuzio_public/thuzio_workday_custom.png';
export const customJpMorganLogo = 'https://storage.googleapis.com/thuzio_public/thuzio_jp_morgan_custom.svg';

export const contactRequestSubmittedIcon = 'https://storage.googleapis.com/thuzio_public/thuzio_request_submitted.svg';

export const contactUsHero = 'https://storage.googleapis.com/thuzio_public/davide-ragusa-gcDwzUGuUoI-unsplash%202.jpg';
